import { useRouter } from 'next/router'
import React from 'react'

const Home = () => {
  const router = useRouter()

  React.useEffect(() => {
    router.push('/annonce')
  })

  return null
}

Home.requireAuth = true

export default Home
